<template>
  <div class="feinongWap">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="top">
      <div class="btn1">
        <div class="activity-name">—— 活动时间 ——</div>
        <div class="activity-time">
          2024年12月02日06:00~2024年12月07日06:00
        </div>
        <!-- <span @click="openLogin" class="btn1" alt="" /> -->
        <img
          class="deal"
          src="/subject/feinongs/wap/button2.png"
          @click="openLogin"
          alt=""
        />
       
      </div>
      <div class="btn2" >

        <div style="position:relative;width:100%">
            <img src="/subject/feinongs/wap/time.png" alt="" class="deal-time">

        <span class="day">{{day}}</span>
          <span class="hours">{{hour}}</span>
          <span class="minute">{{min}}</span>
          <span class="seconds">{{second}}</span>
        </div>
       
      </div>
      <!-- <span @click="openLogin"></span> -->
    </div>
    <div class="part-teacher" v-if="teacherList&&teacherList.length>0" >
     <div class="tit" v-for="(item,index) in teacherList" :key="index">
        <a class="toHref" :href="item.url"></a>
        <img  style="width:100%;height:100%"  :src="item.imgUrl" alt=""   />
     </div>
        <!-- <img class="tit" src="/subject/feinongs/wap/teacher.png" alt="" /> -->
   
    </div>
    <div class="second">
      <img class="tit" src="/subject/feinongs/wap/title.png" alt="" />
      <!-- <p>美元现金1手即领，所有用户均可领取</p> -->
      <!-- <span
        class="detial"
        @click="checkActive(true)"
        style=" color: blue; text-decoration: underline;"
        >活动详情</span
      > -->

      <!-- <img  @click="checkActive(true)" class="detial" src="/subject/feinong/detail_btn.png" alt="" width="120"> -->
        <img class="deal-bg" @click="openLogin" src="/subject/feinongs/wap/deal.png" alt="">

      
      <p class="pTimer">
        
        <span style="color: #ffd9aa;margin-bottom:10px">机会有限，先到先得</span>
        <span>前往APP-我的-活动中心-非农周交易返赠</span>
        <br>
        <!-- 查看活动进度 -->
      </p>
      <div class="btnBox">
        <img @click="openLive" src="/subject/feinongs/wap/request.png" alt="" />
        <img @click="openLogin" src="/subject/feinongs/wap/button.png" alt="" />

      </div>
    </div>
    <div class="third">
      <img class="tit" src="/subject/feinongs/wap/title1.png" alt="" />
      <img src="/subject/feinongs/wap/background1.png" class="tit" alt="" />

      <div class="thirdBox">
      
        
        <!-- <div @mouseleave="tabLeave()">
          <p @mouseover="hoverli(0)" :class="control === 1 ? 'active1' : 'noactive1'"></p>
          <p @mouseover="hoverli(1)" :class="control === 0 ? 'active2' : 'noactive2'"></p>
        </div> -->
        <div class="echartsBox" >
          <div class="flex echart-top">
            <div class="flex yearlist" >
              <div @click="timeChange(index)" v-for="(item,index) in timeList1" :key="index" :class="index==current?'active flex1':'default flex1'">
                {{item}}
              </div>
              
            </div>
            <div class="flex timeselect">
              <div class="flex1">
                <span class="start">开始 </span>
              <el-date-picker
                v-model="start"
                type="date"
                suffix-icon="el-icon-date"
                placeholder="开始时间"
                value-format="yyyy-MM-dd"
                style="width: 64px;height: 19px;border: 1px solid #cccccc;border-radius: 3px;font-size:12px"
                prefix-icon="prefix-icon-class"
                @change="selectPicker"
                :picker-options="pickerOptions0"
              >
              </el-date-picker>

              </div>
              <div class="flex1">
                <span class="start">结束  </span>
              <el-date-picker
                v-model="end"
                type="date"
                suffix-icon="el-icon-date"
                placeholder="结束时间"
                value-format="yyyy-MM-dd"
                style="width: 64px;height: 19px;border: 1px solid #cccccc;border-radius: 3px;font-size:12px"
                prefix-icon="prefix-icon-class"
                @change="selectPicker1"
                :picker-options="pickerOptions1"
              >
              </el-date-picker>
              </div>
            </div>
          </div>
          <div
            id="echartsBox_one"
           
            class="echartsBoxItem"
          ></div>
          <!-- <div
            id="echartsBox_two"
            @mouseover="hoverli(1)"
            v-show="control == 1"
            class="echartsBoxItem"
          ></div> -->
        </div>
        <!-- <dl :class="control === 1 ? 'dlImg' : 'dlImgOther' "></dl> -->
      </div>
    </div>
    <div class="fourth">
      <!-- <img class="tit" src="/subject/feinong/wap/t3.png" alt="" /> -->
      <img class="tit" src="/subject/feinongs/wap/title2.png" alt="" />

     
      <div class="part3-centent">
        

        <div class="part-top">
          <table style="white-space:nowrap;">
            <tr>
              <th>区域</th>
              <th>指标</th>
              <th>前值</th>
              <th>预测值</th>
              <th>公布值</th>
              <th>重要性</th>
              <th>利多 利空</th>
              <!-- <th>解读</th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售月率(%)</th>
              <th>-0.6</th>
              <th>-1.1</th>
              <th>0.4</th>
              <th>/</th>
              <th>利多 加元</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th> -->
            </tr>

            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th>/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country4.png"
                  alt=""
                />
              </th>
              <th>美国8月进口物价指数月率(%)</th>
              <th>0.1</th>
              <th>0.2</th>
              <th>0.3</th>
              <th>高</th>
              <th>利多 金银 石油利空 美元</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country5.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1.6</th>
              <th>0.9</th>
              <th>0.8</th>
              <th>高</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country4.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th>/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country2.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th>/</th>
              <th>/</th>
              <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th>
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/wap/country3.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th>/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/wap/reader.png" alt="" />
              </th> -->
            </tr>
          </table>
          <div class="btnBox">
            <img
              @click=" openLogin"
              src="/subject/feinongs/wap/button.png"
              class="btns"
              alt=""
            />
            <img
              @click="openLive"
              src="/subject/feinongs/wap/request.png"
              class="btns"
              alt=""
            />
          </div>
        </div>
        <!-- <h3><i></i>黄金操作建议</h3> -->

        <div class="part-middle" >
          <p class="address">—— 操作建议 ——</p>
          <!-- <img src="/subject/feinong/part3M2s.png" class="part3M2" alt="" /> -->
          <table id="advice-table">
            <tr>
              <th>品种</th>
              <th>思路</th>
              <th>支持1</th>
              <th>支持2</th>
              <th>阻力1</th>
              <th>阻力2</th>
            </tr>
            <tr>				
              <th>黄金</th>
              <th>逢低看多</th>
              <th>2550</th>
              <th>2588</th>
              <th>2673</th>
              <th>2708</th>
            </tr>
            <tr>
              <th>白银</th>
              <th>逢低看多</th>
              <th>29.14</th>
              <th>29.65</th>
              <th>31.5</th>
              <th>32.5</th>
            </tr>
          </table>
        </div>

        <div class="part-bottom">
          <p class="address" style="margin-bottom:14px">—— 交易研判 ——</p>
                     
                   
          <p class="deal-content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10月实际就业新增人数恐下修成负数；先前的非农就业数据再次大幅下修，8月新增就业人数从15.9万修正至7.8万人，下修8.1万人；9月就业人数则从25.4万修正至22.3万人，下修3.1万人。经修正后，8、9两月的就业人数比未修正前减少11.2万人，整体来看在过去9个月中有7个月的数据遭到下修，甚至有分析预估10月数据会被下修至负值。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;美国劳工部的数据显示，10月的就业(11月释出)成长几乎停滞并创下2020年以来新低，原因有二：第一，10月的数据疲软主要是受到了美国天灾飓风影响。海伦妮和米尔顿台风重创了美国劳动力市场，引起造成数据放缓，另外最重要的原因是，尽管消息被11月市场聚焦的美国大选所覆盖，波音工人罢工所引起的制造业影响的调查回复率。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;但以上的罢工和天然灾害属于偶发性事件，
            <br />
            而这也可以透过我们的失业率所证实：失业率<br/>
            相去不远。而美联储也有针<br/>
            对此事所作出评论：因为两<br />
            件起因都是属于偶然性事件，<br />
            所以这不改变美联储当时11<br />
            月的降息力道和影响后续12<br />
            月甚至2025年的降息路径。
<br/>
            
          </p>
        </div>

        <div class="part-bottom1">
          <p class="address" style="color: #fff;margin-bottom:12px">—— 非农敲黑板 ——</p>
          <p class="deal-content" >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从日线级别走势图来看，自11月初黄金再度缔造历史新高后，整体行情迅速展开修正，由于美联储降息周期的强度将因为特朗普1月20日上</p>
          <div class="deal-contents">
            <p>任和她的关税贸易政策所以预期引起的通膨而对金价有很大的变数。目前市场还未就数据为未来的降息路径进行充分定价，另外市</p>
           
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;场较大的变因如俄乌局势和巴以战争持续升温，这也为金价带来充分的发展空间。
            或着是再次突破历史新高与2750/2800这种整数大关目标后，持续做多！</p>
          
          </div>
          
          <!-- <p
            class="deal-content"
            style="color: #ff3333;  font-size: 12px"
          >
            【以上内容仅代表个人观点，不代表平台立场，仅供参考，并不构成任何操作建议。坚定自己的思路，做好相应风险控制。】
          </p> -->
        </div>
        
      </div>
      <!-- <img
        class="fourthButton"
        @click="openLogin"
        src="/subject/feinong/wap/topButtons.png"
        alt=""
      /> -->
    </div>
    <div class="fifth" >
      <img class="tit" src="/subject/feinongs/wap/title3.png" alt="" />
      <img src="/subject/feinongs/wap/advantage.png" class="tit" alt="" />
      <div class="appCode">
            <p class="appcode-title">
              —— 下载红狮启富app，随时掌握非农动态! ——
            </p>
           <div class="app-box">
             <div class="codeBody">
              <div>MT4账户专用版本</div>
              <div class="codeBox">
                <div class="codeItem">
                  <Qrcode
                    :link="iosMt4DownloadUrl"
                    :size="126"
                    :iconSize="30"
                  />
                  <div>iOS</div>
                </div>
                <div class="codeItem">
                  <Qrcode :link="androidMt4DownloadUrl" :size="126" />
                  <div>Android</div>
                </div>
              </div>
            </div>
            <div class="codeBody">
              <div>MT5账户专用版本</div>
              <div class="codeBox">
                <div class="codeItem">
                  <Qrcode :link="iosMt5DownloadUrl" :size="126" />
                  <div>iOS</div>
                </div>
                <div class="codeItem">
                  <Qrcode :link="androidDownloadUrl" :size="126" />
                  <div>Android</div>
                </div>
              </div>
            </div>
           </div>
          </div>
      <!-- <div class="wapSp">
        <img class="sp" src="/subject/feinong/phone.png" alt="" />
        <div class="wapSpRight">
          <ul>
            <li>多维技术指标</li>
            <li>实时热点更新</li>
            <li>专业建仓建议</li>
            <li>解析非农数据</li>
          </ul>
          <p>—— 下载红狮启富app，随时掌握非农动态! ——</p>
        </div>
      </div>
      <div class="downloadBox">
        <div class="downloadBox-item">
          <div class="downloadBox-item-title">MT4账户专用版本</div>
          <a :href="iosMt4DownloadUrl" class="downloadBox-item-btn">iOS下载</a>
          <a :href="androidMt4DownloadUrl" class="downloadBox-item-btn">Android下载</a>
        </div>
        <div class="downloadBox-item">
          <div class="downloadBox-item-title">MT5账户专用版本</div>
          <a :href="iosMt5DownloadUrl" class="downloadBox-item-btn">iOS下载</a>
          <a :href="androidDownloadUrl" class="downloadBox-item-btn">Android下载</a>
        </div>
      </div> -->
      <div class="serveBox">
        <div class="serveBox-item">
          <span>1、</span>MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；
        </div>
        <div class="serveBox-item">
            <span>2、</span>
            <p>
              本平台2024年8月31日10：00之前开立的账户，均为MT4账户。之后开立的账户均为MT5账户。如果不确定，
             请联系<span class="serveBox-serve" @click="openLive">在线客服</span>查询。
            </p>
        </div>
      </div>
      <div class="fifth-tips">
        红狮专注伦敦金/银15年 只为给您这1秒的交易<br>
        带来更专业的体验
      </div>
    </div>
    <img src="/subject/feinongs/wap/bottom.png" class="bottom-bg" alt="">

    <!-- <contactusVue v-if="!isApp"></contactusVue> -->
    <div v-if="showDialog" class="dialog">
      <img
        class="closer"
        @click="checkActive(false)"
        src="/subject/feinong/wap/guanbi2.png"
        alt=""
      />
      <ul class="dialogContent">
        <li>
          <span>活动对象：</span>
          本公司所有新老用户
        </li>
        <li>
          <span>活动交易时间：</span>
          <!-- {{ activityTime.FNStartTime }}—{{ activityTime.FNEndTime }} -->
          2024年12年02日06:00—2024年12年07日06:00
        </li>
        <li>
          <span>兑奖截止时间：</span>
          <!-- {{ activityTime.FNExchangeEndTime }}（逾期失效） -->
          2024年12月25日00:00（逾期失效）
        </li>
        <li>
          <span>活动规则：</span><br />
          活动期间内，凡在本公司的APP或MT交易平台交易伦敦金、伦敦银等指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次；
        </li>
        <li>
          <span>交易奖励：</span><br />
          <table>
            <tr>
              <th>赠送条件</th>
              <th>美元现金奖励</th>
            </tr>
            <tr>
              <th>≥1手</th>
              <th>1美元</th>
            </tr>
            <tr>
              <th>≥5手</th>
              <th>10美元</th>
            </tr>
            <tr>
              <th>≥10手</th>
              <th>25美元</th>
            </tr>
            <tr>
              <th>≥50手</th>
              <th>150美元</th>
            </tr>
            <tr>
              <th>≥100手</th>
              <th>350美元</th>
            </tr>
            <tr>
              <th>≥500手</th>
              <th>1750美元</th>
            </tr>
          </table>
        </li>
        <li>
          <span>领取方式：</span><br />
          1.非农奖励在“APP”→“我的”→“活动中心”→“非农活动”中领取；<br />
          2.活动只计算非农周交易手数，奖励会在非农交易周之后开启兑换； <br />
          3.奖励后的美元可直接提现，亦可继续用于交易；<br />
          4.美元现金奖励需在兑奖截止日期前领取，逾期失效；<br />
        </li>
        <li>
          <span>注意事项</span><br />
          1.客户参与活动需在本公司开立真实交易账户，为保证资金安全，参与该活动的新用户，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br />
          2.本活动指定品种详情可咨询客服，交易手数计算以开平仓为准；<br />
          3.各账户层级用户均可参加本活动，美元现金奖励将依据活动交易期间的最终开平仓手数发放；<br />
          4.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br />
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog"></div>
  </div>
</template>

<script>
import ContactusVue from '../../Contactus.vue';
import { getFeinongTime } from '../../../../api/info';
// import { getSYLApi, getFNApi } from '../../../../api/subject/feinong';
import { getTeacherList, getTime,getSYLApi, getFNApi } from "../../../../api/subject/hdtime";
import { mapState  } from "vuex";
import Qrcode from '../../QrCode/index.vue'
export default {
  name: 'newfeinongWap',
  components: {
    ContactusVue,
    Qrcode,
  },
  data() {
    return {
      curStartTime: '2024-07-06 06:00:00',
      day: '00',
      hour: '00',
      min: '00',
      second: '00',
      control: 0,
      timer: Function,
      showDialog: false,
      navBarFixed: false,
      scroll: '',
      isApp: false,
      activityTime: {
        FNStartTime: null,
        FNEndTime: null,
        FNExchangeEndTime: null,
      },
      arrx1: [],     
      timeList1:[
        '半年','一年','两年','三年'
      ],
      current:1,
      form: {
        languageIdentification: "1",
        type: "61",
        brandId: "1",
        region: "1",
      },
      form1: {
        key: "FNReleaseDate",
      },
      forms: {
        startTime: "2022-05-05",
        endTime: "2023-05-05",
      },
      // 开始时间、
      start: "2022-05-05",
      // 结束时间
      end: "2023-05-05",
      teacherList:[],
      
      pickerOptions0: {
                disabledDate: (time) => {
                    if (this.end != "") {
                        let curDate = (new Date()).getTime();
            let three = 3*365 * 24 * 3600 * 1000;
            let threeMonths = curDate - three;
                        return time.getTime() > Date.now() || time.getTime() > this.end|| time.getTime() < threeMonths;;
                    } else {
                        return time.getTime() > Date.now();
                    }

                }
            },
            pickerOptions1: {
                disabledDate: (time) => {
                    return time.getTime() < this.start || time.getTime() > Date.now();
                }
            },
    };
  },
  computed: {
    ...mapState('common', {
      androidMt4DownloadUrl: 'androidMt4DownloadUrl',
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  created() {
    const currentDate = new Date();
    const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
        this.start = this.getSelectData(oneYearAgo);
        this.forms.startTime=this.getSelectData(oneYearAgo);
    this.end=this.getNowFormatDate()
    this.forms.endTime=this.getNowFormatDate()
    this.$emit('controlShow', true);
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem('craig')) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem('craig', window.location.origin);
      } else {
        sessionStorage.setItem('craig', this.$route.query.craig);
      }
    }
    this.timer = setInterval(this.updateDiv, 3000);
    if (this.$route.query.isApp) {
      this.isApp = true;
    }
    // 获取 活动 时间
    getFeinongTime().then((res) => {
      this.activityTime = res.data;
    });
     // 美国非农就业
    this.getJoblist()
    // 美国失业率报告
    this.lostJoblist()
     // 获取倒计时
    getTime(this.form1).then((res) => {
      console.log("倒计时数据", res);
      this.curStartTime = res.Data;
      
      this.countTime();
    });
    // 讲师列表
    getTeacherList(this.form).then((res) => {
      console.log("讲师数据--------------", res);
      this.teacherList=res.Data
    });
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    // this.countTime();
  },
  methods: {
    //获取当前时间
     getNowFormatDate() {
  let date = new Date(),
    year = date.getFullYear(), //获取完整的年份(4位)
    month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
    strDate = date.getDate() // 获取当前日(1-31)
  if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
  if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
 
  return `${year}-${month}-${strDate}`
},
    // 选择时间
    timeChange(index) {
      this.current = index;
      // 获取当前日期
      const currentDate = new Date();

      // 获取半年前的时间
      if(index==0){
         const sixMonthsAgo = new Date();
         sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
          this.forms.start=this.getSelectData(sixMonthsAgo)
          this.forms.startTime=this.getSelectData(sixMonthsAgo)
          
      }
      

      // 获取一年前的时间
      if(index==1){
          const oneYearAgo = new Date();
          oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
          this.start=this.getSelectData(oneYearAgo)
          this.forms.startTime=this.getSelectData(oneYearAgo)
          

      }
      
      // 获取2年前的时间
      if(index==2){
         const twoYearAgo = new Date();
         twoYearAgo.setFullYear(currentDate.getFullYear() - 2);
          console.log( this.getSelectData(twoYearAgo))
          this.start=this.getSelectData(twoYearAgo)
          this.forms.startTime=this.getSelectData(twoYearAgo)
          
      }
      
      // 获取3年前的时间
      if(index==3){
         const threeYearAgo = new Date();
         threeYearAgo.setFullYear(currentDate.getFullYear() - 3);
          console.log( this.getSelectData(threeYearAgo))
          this.start=this.getSelectData(threeYearAgo)
          this.forms.startTime=this.getSelectData(threeYearAgo)
          
      }
       this.getJoblist()
        this.lostJoblist()
    
    },
    // 获取选择时间
    getSelectData(data) {
      
      // 将日期对象转换为自定义日期格式
      const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
       return formatDateString(data)
 
      
    },
    //  选择开始时间
    selectPicker(e){
      console.log(e)
     
          var startNum = parseInt(e.replace(/-/g,''),10);
             var endNum = parseInt(this.end.replace(/-/g,''),10);
       if(startNum>endNum){
        this.$message('开始时间不能大于结束时间！');
        return false
       }

      this.current=null
       this.start=e
      this.forms.startTime=e
      this.getJoblist()
      this.lostJoblist()
    },
    //  选择结束时间
    selectPicker1(e){
      console.log(e)
        var startNum = parseInt(this.start.replace(/-/g,''),10);
             var endNum = parseInt(e.replace(/-/g,''),10);
       if(startNum>endNum){
        this.$message('开始时间不能大于结束时间！');
        return false
       }
      this.current=null
      this.end=e
      this.forms.endTime=e
       this.getJoblist()
      this.lostJoblist()
    },
  
      // 非农就业
    getJoblist(){
       getFNApi(this.forms).then((res) => {
      var arr_X = [];
      var arr_Y = [];
      var arr = res.dt
      if(arr&&arr.length>0){
for (let i = arr.length - 1; i >= 0; i--) {
        arr_X.push(arr[i].date.slice(0, 10));
        arr_Y.push(arr[i].announcement);
      }
      this.arr1=arr_Y
      }else{
        this.arr1=[]
      }
      
     
    });
  
    
    },
    // 失业人数
    lostJoblist(){
        getSYLApi(this.forms).then((res) => {
      var arr_X = [];
      var arr_Y = [];
      var arr = res.dt;
      if(arr&&arr.length>0){
          for (let i = arr.length - 1; i >= 0; i--) {
        arr_X.push(arr[i].date.slice(0, 10));
        arr_Y.push(arr[i].announcement);
      }
      }
      
      let myChart = this.$echarts.init(document.getElementById('echartsBox_one'));
      let option = {
        
         legend: {
          data: ["美国失业率", "美国非农就业人数"],
          textStyle: { fontSize: 12 },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove',
          enterable: true, //鼠标是否可进入提示框浮层中
          formatter: this.formatterHover, //修改鼠标悬停显示的内容
        },
        dataZoom: [
          
          { 
            show: true,
            realtime: true,
            type: 'inside',
            xAxisIndex: 0,
            zoomLock: true,
            moveOnMouseMove: true,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            startValue: 1, //数据窗口范围的起始数值
            endValue: 4,
          },
          { 
           
            realtime: true,
            type: 'inside',
            xAxisIndex: 0,
            zoomLock: true,
            moveOnMouseMove: true,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            startValue: 1, //数据窗口范围的起始数值
            endValue: 4,
          },
        ],
        grid: {
          // 距离容器上边界40像素
          bottom: '20%', // 下边界距离
          right: '10%', // 右边界距离
          left: '15%', // 左边界距离
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: arr_X,
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          name: '今值(万)',
        },
        series: [
          { 
            name: "美国失业率",
            data: arr_Y,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: '#ff3333',
            },
            
          },
          { 
            name: "美国非农就业人数",
            data: this.arr1,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: '#3333ff',
            },
            areaStyle: {
              normal: {
                color: '#e9f1fe',
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    });
     
    },
    formatterHover(params) {
      return (
        '<span style="padding-left:5px;height:30px;line-height:30px;display: inline-block;">' +
        params[0].axisValue +
        '<br>今值: ' +
        params[0].data +
        '</span>'
      );
    },
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem('craig');
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 三元表达式判断不满10前面加0
        this.day = day < 10 ? '0' + day : day;
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? '0' + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? '0' + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? '0' + s : s;
      } else {
        this.day = '00';
        this.hour = '00';
        this.min = '00';
        this.second = '00';
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    },

    // 鼠标进入事件
    hoverli(attr) {
      clearInterval(this.timer);
      this.control = attr;
    },
    // tabLeave鼠标离开事件
    tabLeave() {
      this.timer = setInterval(this.updateDiv, 3000);
    },
    // 切换rollBox的方法
    updateDiv() {
      if (this.control > 0) {
        this.control = this.control - 1;
      } else {
        this.control = 1;
      }
    },
    // 查看活动
    checkActive(attr) {
      this.showDialog = attr;
    },
    openLogin() {
      window.open('https://www.rlcproltd.net/uc/login');
    },
    openLive() {
      window.open(
        this.$parent.live800Url
        // 'https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377'
      );
    },
    
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner{
  height: 19px !important;
  padding: 0 5px !important;
  font-size: 10px;
}
.tabbar {
  width: 100%;
  background-color: #ffffff;

  img {
    margin: 10px 30px;
    width: 130px;
  }
}

.navBarFixed {
  position: fixed;
  top: 0;
  z-index: 999;
}
.feinongWap {
  width: 100%;
  background: url("/subject/feinongs/wap/bg.png") no-repeat center;
  background-size: 100% 100%;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top {
    width: 100%;
    height: 480px;
    // background: url('/subject/feinong/wap/topBanner.jpg') no-repeat;
    background: url('/subject/feinongs/wap/topBanner.png') no-repeat;
    background-size: 100% 100%;
    position: relative;

    span {
      position: absolute;
      top: 71%;
      left: 50%;
      transform: translate(-50%, -50%);
      // height: 40px;
      // width: 152px;
    }
    .deal {
      width: 196px;
      height: 40px;
      margin-top: 40px;
    }
    .deal-time {
      width: 100%;
      // height:140px;
      height:auto;
      
    }
    .activity-name {
      width: 186px;
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 73.73px 0px rgba(15, 21, 63, 0.8);
    }
    .activity-time {
      margin-top: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 73.73px 0px rgba(15, 21, 63, 0.8);
    }
    .btn1 {
      position: absolute;
      // width: 314px;
      height: 84px;
      left: 46%;
      top: 442px;
      top: 54%;
      left: 50%;
      cursor: pointer;
      margin-left: -160px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .btn2 {
      position: absolute;
      top: 80%;
      left: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      // margin-left: -160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;

      font-weight: 400;
      text-align: center;
      color: #ffffff;

      .day {
        position: absolute;
        left: 35%;
        top: 56%;
        z-index: 10;
      }
      .hours {
        position: absolute;
        left: 49%;
        top: 56%;
        z-index: 10;

      }
      .minute {
        position: absolute;
        left: 63%;
        top: 56%;
        z-index: 10;

      }
      .seconds {
        position: absolute;
        left: 78%;
        top: 56%;
        z-index: 10;

      }
     
    }
  }
  .pTimer {
    display: flex;
    flex-direction: column;
    text-align: center;
    // color: #e26f08 !important;
    margin-top: 30px;
    span {
      // color: red;
      color: #fff;
    }
  }
  .part-teacher{
    width: 96%;
    box-sizing: border-box;
    margin-top: 60px;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
     .tit {
      width: 100%;
      height: 251px;
      //padding-left: 0.5rem;
      position: relative;
    }
    .toHref{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      cursor: pointer;
        width: 100%;
      height: 251px;
    }
  }
  .second {
    width: 100%;
    box-sizing: border-box;
    padding-top: 5px;
    // background: #fffef8;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;
    position: relative;
    .tit {
      width: 90%;
      height: auto;
      position: absolute;
      top: -6%;
      left: 5%;
    }

    p {
      font-size: 14px;
      color: #000;
    }

    .detial {
      font-size: 14px;
      color: #000;
      margin: 8px auto 8px;
    }
      .deal-bg{
        // margin-top: 80px;
        width: 100%;
        height: 388px;
      }
    .secondBox {
      width: 98%;
      height: 240px;

      box-sizing: border-box;
      padding: 30px 0;
      margin-bottom: 8px;
    
      ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          width: 125px;
          height: 93px;
          background: url('/subject/feinong/wap/secondImg1s.png') no-repeat;
          background-size: 100% 100%;
          margin: 0 3px;
          position: relative;

          img {
            width: 28px;
            height: auto;
            position: absolute;
            bottom: 7px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }

        li:nth-child(2) {
          background: url('/subject/feinong/wap/secondImg2s.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .ulOther {
        margin-top: 5px;

        li:nth-child(1) {
          background: url('/subject/feinong/wap/secondImg3s.png') no-repeat;
          background-size: 100% 100%;
        }

        li:nth-child(2) {
          background: url('/subject/feinong/wap/secondImg4s.png') no-repeat;
          background-size: 100% 100%;
        }

        li:nth-child(3) {
          background: url('/subject/feinong/wap/secondImg5s.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    
  }
  .btnBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 186px;
        height: 74px;
        
        // margin: 8px 12px;
      }
    }
  .third {
    width: 100%;

    // background: url('/subject/feinong/wap/thirdBackgrounds.jpg') no-repeat;
    // background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 40px;

    .tit {
      width: 85%;
      height: auto;
      margin-bottom: 10px;
    }

    .thirdBox {
      width: 404px;
      padding-bottom: 40px;

      ul {
        box-sizing: border-box;
        padding: 10px 20px 20px 24px;

        li {
          font-size: 14px;
          color: #666666;
          line-height: 25px;
          margin-bottom: 3px;

          span {
            display: inline-block;
            width: 7px;
            height: 7px;
            background-color: #efaa05;
            border-radius: 50%;
            margin-right: 5px;
          }

          i {
            width: 13px;
            height: 13.5px;
            display: inline-block;
            background: url('/subject/feinong/icon_l.png') no-repeat;
            background-size: 100% 100%;
            margin: 0 7px;
          }
        }
      }

      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 167.5px;
          height: 55px;
        }

        .noactive1 {
          background: url('/subject/feinong/wap/roll3.png') no-repeat;
          background-size: 100% 100%;
        }

        .noactive2 {
          background: url('/subject/feinong/wap/roll1.png') no-repeat;
          background-size: 100% 100%;
        }

        .active1 {
          background: url('/subject/feinong/wap/roll4.png') no-repeat;
          background-size: 100% 100%;
        }

        .active2 {
          background: url('/subject/feinong/wap/roll2.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .echartsBox {
        padding: 20px 12px;
        width: 335px;
        height: 200px;
        background-color: #fff;
        margin: 0 auto;
        background: url("/subject/feinongs/wap/border3.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
       
          .yearlist{
            width: 60%;
            font-size: 10px;
          }
          .start{
            margin-right: 5px;
            font-size: 10px;
          }
          .timeselect{
            // flex: 1;
            

          }
          .flex1{
           display: flex;
            justify-content: center;
            align-items: center;
          }
          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .default {
            font-size: 10px;
            font-weight: 400;
            text-align: center;
            color: #999999;
            width: 30px;
            height: 19px;
            background: #fff0f0;
            border-radius: 3px;
          }
          .active {
            font-size: 10px;
            font-weight: 400;
            text-align: center;
            color: #ff3333;
            width: 30px;
            height: 19px;
            background: #ffe0e0;
            border-radius: 3px;
          }
        .echartsBoxItem {
          width: 330px;
          height: 200px;
          margin: 0 auto;
        }
      }
    }
  }

  .fourth {
    width: 100%;
    padding: 0px 2% 0 5%;
    margin: auto;
    
    // background: #fffef8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    
    .tit {
      width: 85%;
      height: auto;
      margin-bottom: 10px;
    }

    .fourthKK {
      display: block;
      margin: 0 auto;
      width: 97%;
      height: auto;

      padding-top: 5px;
      box-sizing: border-box;
      padding-left: 20px;
      padding-bottom: 30px;
      margin-bottom: 10px;

      h3 {
        font-size: 18px;
        color: #efaa05;
        margin: 10px 0;

        i {
          display: inline-block;
          width: 3px;
          height: 13px;
          margin-right: 10px;
          background: #efaa05;
          border-radius: 5px;
        }
      }

      table {
        width: 95%;
        border: 1px solid #e28172;
        border-radius: 10px;
        background-color: #fffbee;

        tr {
          th {
            text-align: center;
            font-weight: 500;
            height: 40px;
            border-bottom: 1px solid #c4693b;
            border-right: 1px solid #c4693b;
            padding: 5px;
            color: #c4693b;
            font-size: 14px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            width: 37%;
            font-size: 16px;
            font-weight: 600;
            color: #cd7f4e;
          }
        }

        tr:last-child {
          th {
            border-bottom: none;
          }
        }
      }

      .part3M2 {
        width: 95%;
        margin-bottom: 10px;
        border-radius: 10px;
      }
      b {
        color: #efaa05;
      }
      p {
        width: 95%;
        font-size: 14px;
        color: #666;
        line-height: 24px;
        text-align: justify;
      }

      .sp {
        font-size: 12px;
        opacity: 0.5;
        line-height: 20px;
      }
    }
     .part3-centent {
      display: block;
      margin: 0 auto;
      // width: 1333px;
      width: 97%;
      // height: 1505px;

      padding-top: 30px;
      box-sizing: border-box;
      // padding-left: 174px;
      .address {
        font-size: 14px;

        font-weight: 700;
        text-align: center;
        color: #dc9948;
        text-shadow: 0px 0px 61.2px 0px rgba(15, 21, 63, 0.8);
        margin-bottom: 14px;
      }
      h3 {
        font-size: 40px;
        color: #efaa05;

        i {
          display: inline-block;
          width: 5px;
          height: 24px;
          margin-right: 12px;
          background: #efaa05;
          border-radius: 5px;
        }
      }

      //
      .part-top {
        overflow: auto;
        width: 100%;
        // height: 608px;
        padding: 21px 0 30px 16px;
        background: url("/subject/feinongs/wap/border3.png") no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
      }

      // 操作建议
      .part-middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 98%;
        background: url("/subject/feinongs/wap/border.png") no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 14px 0 14px 0;
        margin: 20px 0 22px 0;
      }

      // 建议研判
      .part-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        // height: 396px;
        background: url("/subject/feinongs/wap/border1.png") no-repeat;
        background-size: 100% 100%;
        padding: 14px 16px 20px;
        box-sizing: border-box;
        .deal-content {
          // width: 324px;
          font-size: 12px;

          font-weight: 400;

          color: #ffffff;
          line-height: 20px;
        }
      }

      .part-bottom1 {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        // height: 396px;
        background: url("/subject/feinongs/wap/background.png") no-repeat;
        background-size: 100% 100%;
        padding: 14px 16px 20px;
        box-sizing: border-box;
        position: relative;
        .deal-content {
          //  width: 324px;
          font-size: 12px;

          font-weight: 400;

          color: #ffffff;
          line-height: 20px;
          margin-bottom:64%;
        
        }
      }
      .deal-contents {
        position: absolute;
        left: 34%;
        bottom: 10%;
        p{
          font-size: 12px;

          font-weight: 400;

          color: #ffffff;
          line-height: 20px;
        }
          //  width: 324px;
          
        }
      #advice-table {
        padding: 14px 16px 20px 14px;
        // width: 70%;
        
        tr {
          background: linear-gradient(
              0deg,
              rgba(0, 19, 171, 0.16) 0%,
              rgba(0, 25, 243, 0.16) 50%,
              rgba(0, 20, 166, 0.16) 100%
            ),
            rgba(255, 255, 255, 0);
          
             border-left: 1px solid #0017cb;
            border-right: 1px solid #0017cb;
          th {
            text-align: center;
            font-weight: 500;
            line-height: 35px;
            border-bottom: 1px solid #0017CB;
            
            padding: 5px;
            color: #fff;
            font-size: 12px;
          }
        }
        tr:nth-child(1) {
          th {
            background: linear-gradient(-90deg, #ffae59 0%, #ffcb7e 100%),
              #ffffff;
            background-color: #ff2c41;
            line-height: 30px;
            font-weight: 700;
            text-align: center;
            color: #84490d;
          }
        }
      }
      table {
        
        // border: 2px solid #e28172;
        border-radius: 16px;
        // background-color: #fffbee;
        border-collapse:collapse;
        
        .country {
          width: 27px;
          height: 18px;
        }
        .reader {
          width: 16px;
          height: 16px;
        }
        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 60px;
            // border-bottom: 1px solid #c4693b;
            // border-right: 1px solid #c4693b;
            border-bottom: 1px solid #E5E5E5;
 
            padding: 5px;
            color: #333;
            font-size: 14px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            // width: 35%;
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            background-color: #ff2c41;
            border-bottom: 1px solid #E5E5E5;

            line-height: 38px;
            border: 0;
          }
        }
        tr:nth-child(4),tr:nth-child(5) {
          th {
          
            
            line-height: 48px;
            font-weight: 700;
text-align: center;
color: #ff3333;
          }
        }
        tr:last-child {
          th {
            border-bottom: none;
          }
        }
      }

      .part3M2 {
        width: 93%;
        margin-bottom: 10px;
        border-radius: 16px;
      }
      b {
        color: #efaa05;
      }
      p {
        font-size: 24px;
        color: #666666;
        // line-height: 48px;
        // width: 1200px;
      }

      .sp {
        font-size: 14px;
        opacity: 0.5;
        line-height: 30px;
      }
    }
    .fourthButton {
      height: 33px;
      width: auto;
    }
  }

  .fifth {
    width: 100%;
    padding: 40px 8% 0;
    margin: auto;
    // background: url('/subject/feinong/wap/fifthBackgrounds.jpg') no-repeat;
    // background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    .tit {
      width: 95%;
      height: auto;
    }
     .appCode {
        // margin-left: 40px;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        text-align: center;
        background: url("/subject/feinongs/wap/border4.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 15px;
        .app-box{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        // height: 302px;
        padding: 30px;
        .appcode-title {
          font-size: 0.9rem;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #ff3333;
          line-height: 28px;
          margin-bottom: 13px;
        }
        .codeBody {
          > div:first-child {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 14px;
          }
          .codeBox {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            margin-right: 30px;
            .codeItem {
              &:first-child {
                margin-right: 15px;
              }
              .canvasCode {
                border-radius: 6px;
              }
              div {
                line-height: 32px;
                // line-height: 48px;
                // background: #e4760b;
                background: linear-gradient(150deg, #ff2c41 0%, #ff1232 100%),
                  #ff3333;
                color: #fff;
                border-radius: 4px;
                margin-top: 6px;
                // font-size: 20px;
                font-size: 14px;
              }
            }
          }
        }
      }
    .wapSp {
      display: flex;
      align-items: center;
      margin: 20px auto;
      .sp {
        width: 48%;
        margin-right: 8%;
      }
      
      .wapSpRight {
        flex: 1;
        ul {
          li {
            width: 100%;
            text-align: center;
            color: #e4760b;
            font-size: 15px;
            line-height: 37px;
            margin-bottom: 20px;
            font-weight: bold;
            border: 1px solid #e4760b;
            border-radius: 5px;
            display: inline-block;
          }
        }
        p {
          color: #E4760B;
          text-align: center;
          font-size: 0.9rem;
        }
      }
    }
    .downloadBox {
      width: 100%;
      display: flex;
      text-align: center;
      &-item {
        flex: 1;
        &:first-child {
          border-right: 1px solid #E4760B;
          padding-right: 5%;
          margin-right: 5%;
        }
        &-title {
          font-weight: bold;
        }
        &-btn {
          display: block;
          background: #008AEE;
          border-radius: 5px;
          line-height: 2.3rem;
          font-size: 0.85rem;
          color: #fff;
          font-weight: bold;
          margin-top: 15px;
        }
      }
    }
    .serveBox {
      font-size: 0.68rem;
      font-size: 12px;
      margin: 15px 0;
      color: #fff;
      &-serve {
        color: red;
        border-bottom: 1px solid red;
      }
      &-item{
        display: flex;
      
      }
    }
    .fifth-tips {
      text-align: center;
      font-size: 0.85rem;
      color: #FF3333;
      font-weight: bold;
      margin: 10px 0 40px;
    }
  }
  .bottom-bg{
     width: 100%;
     height: 342px;
  }
  .dialog {
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 480px;
    box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fdf5e6;
    border-radius: 15px;
    overflow-y: scroll;
    z-index: 99999999;

    .closer {
      width: 23px;
      height: auto;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .dialogContent {
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;

      li {
        font-size: 14px;
        color: #a86e4f;
        line-height: 24px;

        span {
          font-weight: 600;
          color: #e34a3f;
        }

        img {
          width: 100%;
          height: auto;
          margin: 20px 0;
        }

        table {
          width: 100%;
          border: 1px solid #e34a3f;
          border-radius: 10px;

          tr {
            th {
              text-align: center;
              font-weight: 500;
              line-height: 24px;
              border-bottom: 1px solid #d2a765;
              border-right: 1px solid #d2a765;
              padding: 5px;
            }

            th:last-child {
              border-right: 1px solid transparent;
            }
          }

          tr:nth-child(1) {
            th {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
  }
}

@media screen and (min-width: 400px) {
  .feinongWap {
    .fourth {
      // height: 800px;
    }
  }
}
</style>
